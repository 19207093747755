import { GetLandLordByUUID_landlordByUUID } from '../../../../graphql/types/GetLandLordByUUID';
import { ApprovalType } from '../../../controls/approval-state/approval-state';
import { LandlordFormDataState } from './landlord-view.state';
import { Gender } from '../../../../graphql/types/globalTypes';

export const getApprovalState = (landlord: GetLandLordByUUID_landlordByUUID): ApprovalType => {
  const { blockedBy, verifiedAt } = landlord;

  if (blockedBy !== null) {
    return ApprovalType.Blocked;
  } else if (!verifiedAt) {
    return ApprovalType.Inactivated;
  }
  return ApprovalType.Approved;
};

export const getFormData = (queryResult: GetLandLordByUUID_landlordByUUID): LandlordFormDataState => {
  // eslint-disable-next-line spellcheck/spell-checker, @typescript-eslint/no-unused-vars
  const { __typename, listings, ...landlord } = queryResult;
  return Object.assign(
    {
      approvalState: getApprovalState(queryResult),
      isBlocked: queryResult.blockedBy !== null,
      password: null,
      passwordConfirmed: null,
      gender: queryResult.gender || Gender.DIVERSE,
      privacyPolicyAccepted: true, // Assume that only users with accepted policy are in the database
      propertyOwnershipConfirmed: queryResult.propertyOwnershipConfirmed
    },
    landlord
  );
};

export const getValueOrPlaceholderString = (value: string | null, isEditMode: boolean): string => {
  return value && value.length > 0 ? value : isEditMode ? '' : '\u2013';
};
