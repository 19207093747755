import * as Yup from 'yup';
import { ContactType } from '../../../graphql/types/globalTypes';
import { testOptionalStringLength } from '../../../utils/validation/state-validation-checks';

export const registerAuthValidationSchema = Yup.object().shape(
  {
    email: Yup.string().typeError('errors.required').required('errors.required').email('errors.invalidMail'),
    password: Yup.string().typeError('errors.required').required('errors.required').min(8, 'errors.passwordMin'),
    passwordConfirmed: Yup.string().oneOf([Yup.ref('password'), null], 'errors.passwordNotEqual')
  },
  []
);

export const registerValidationShape = {
  contactType: Yup.mixed().oneOf(Object.values(ContactType)),
  firstName: Yup.string().typeError('errors.required').required('errors.required').min(2, 'errors.tooShort2'),
  lastName: Yup.string().typeError('errors.required').required('errors.required').min(2, 'errors.tooShort2'),
  phone: Yup.string()
    .transform((value) => (value === '' ? null : value))
    .typeError('errors.required')
    .required('errors.required')
    .test('hasMinLength', 'errors.tooShort6', testOptionalStringLength(5)),
  companyName: Yup.string().when('contactType', {
    is: (contactType: ContactType) =>
      contactType === ContactType.COMPANY || contactType === ContactType.PUBLIC_INSTITUTION,
    then: Yup.string().required('errors.required').min(3, 'errors.tooShort3'),
    otherwise: Yup.string().nullable()
  }),
  street: Yup.string().typeError('errors.required').required('errors.required').min(2, 'errors.tooShort2'),
  houseNumber: Yup.string().typeError('errors.required').required('errors.required'),
  postalCode: Yup.string().typeError('errors.required').required('errors.required').min(3, 'errors.tooShort3'),
  city: Yup.string().typeError('errors.required').required('errors.required').min(2, 'errors.tooShort2'),
  agreement: Yup.boolean().isTrue(),
  ownership: Yup.boolean().isTrue()
};

export const registerValidationSchema = Yup.object().shape(registerValidationShape, []);
