import { gql } from '@apollo/client';
import { landlordContactInfoFragment } from './listings';

export const GET_LANDLORDS = gql`
  # Get all landlords for the landlord view
  query GetLandlords($resultLimit: Int, $pageOffset: Int, $orderBy: LandlordSortOrder, $filter: LandlordFilter) {
    landlords(resultLimit: $resultLimit, pageOffset: $pageOffset, orderBy: $orderBy, filter: $filter) {
      id
      uuid
      isNew
      firstName
      lastName
      email
      contactType
      numberOfActiveListings
      verifiedAt
      blockedAt
    }
  }
`;

export const GET_LANDLORDS_EXPORT = gql`
  # Get all landlords for the landlord view
  query GetLandlordsExport($orderBy: LandlordSortOrder, $filter: LandlordFilter) {
    landlords(orderBy: $orderBy, filter: $filter) {
      uuid
      firstName
      lastName
      email
      phone
      contactType
      numberOfActiveListings
      status
      notes
    }
  }
`;

export const GET_LANDLORDS_FOR_SEARCH = gql`
  ${landlordContactInfoFragment}
  # Get landlords for the landlord search combo box
  query GetLandlordsForSearch(
    $resultLimit: Int
    $pageOffset: Int
    $orderBy: LandlordSortOrder
    $filter: LandlordFilter
  ) {
    landlords(resultLimit: $resultLimit, pageOffset: $pageOffset, orderBy: $orderBy, filter: $filter) {
      id
      uuid
      contactType
      ...landlordContactInfo
    }
  }
`;

export const GET_NUMBER_OF_LANDLORDS = gql`
  # Get the total number of landlords
  query NumberOfLandlords($filter: LandlordFilter) {
    numberOfLandlords(filter: $filter)
  }
`;

export const GET_NUMBER_OF_NEW_LANDLORDS = gql`
  # Get the total number of landlords
  query NumberOfNewLandlords {
    numberOfNewLandlords
  }
`;

export const POST_NEW_LANDLORD = gql`
  # Create a new landlord
  mutation PostNewLandlord(
    $firstName: String
    $lastName: String!
    $contactType: ContactType!
    $email: String
    $notes: String
    $companyName: String
    $phone: String
    $street: String
    $houseNumber: String
    $postalCode: String
    $city: String
    $gender: Gender
    $privacyPolicyAccepted: Boolean!
    $propertyOwnershipConfirmed: Boolean!
  ) {
    addLandlord(
      firstName: $firstName
      lastName: $lastName
      contactType: $contactType
      email: $email
      notes: $notes
      companyName: $companyName
      phone: $phone
      street: $street
      houseNumber: $houseNumber
      postalCode: $postalCode
      city: $city
      gender: $gender
      privacyPolicyAccepted: $privacyPolicyAccepted
      propertyOwnershipConfirmed: $propertyOwnershipConfirmed
    )
  }
`;

export const POST_REGISTER_LANDLORD = gql`
  # Create a new landlord
  mutation PostRegisterLandlord(
    $firstName: String!
    $lastName: String!
    $contactType: ContactType!
    $email: String!
    $companyName: String
    $phone: String!
    $street: String!
    $houseNumber: String!
    $postalCode: String!
    $city: String!
    $gender: Gender!
    $password: String!
    $privacyPolicyAccepted: Boolean!
    $propertyOwnershipConfirmed: Boolean!
  ) {
    registerLandlord(
      firstName: $firstName
      lastName: $lastName
      contactType: $contactType
      email: $email
      companyName: $companyName
      phone: $phone
      street: $street
      houseNumber: $houseNumber
      postalCode: $postalCode
      city: $city
      gender: $gender
      password: $password
      privacyPolicyAccepted: $privacyPolicyAccepted
      propertyOwnershipConfirmed: $propertyOwnershipConfirmed
    )
  }
`;

export const PUT_UPDATE_LANDLORD = gql`
  mutation UpdateLandlord($landlord: LandlordInput!) {
    updateLandlord(landlord: $landlord)
  }
`;

export const landlordListingFragment = gql`
  fragment landlordListingFragment on Listing {
    id
    uuid
    totalRent
    type
    isActive
    isBlocked
    numberOfRooms
    street
    houseNumber
    postalCode
    city
    tumLocation
    district
    availableFrom
    squareMeter
    tags
    previewImage {
      id
      modifiedAt
      description
      descriptionEn
    }
  }
`;

export const GET_LANDLORD_BY_ID = gql`
  ${landlordListingFragment}
  query GetLandLordById($id: ID!) {
    landlord(id: $id) {
      contactType
      companyName
      firstName
      lastName
      phone
      email
      street
      houseNumber
      postalCode
      city
      gender
      showFirstName
      showLastName
      showEmail
      showPhone
      showCompany
      verifiedAt
      blockedBy
      listings {
        ...landlordListingFragment
      }
    }
  }
`;

export const GET_LANDLORD_BY_UUID = gql`
  ${landlordListingFragment}
  query GetLandLordByUUID($uuid: String!) {
    landlordByUUID(uuid: $uuid) {
      id
      uuid
      contactType
      companyName
      firstName
      lastName
      phone
      email
      notes
      isNew
      lastEditedBy
      modifiedAt
      verifiedAt
      verifiedBy
      blockedBy
      blockedReason
      blockedAt
      createdAt
      isNew
      street
      houseNumber
      postalCode
      city
      gender
      listings {
        ...landlordListingFragment
      }
    }
  }
`;

export const POST_BLOCK_LANDLORD = gql`
  mutation PostBlockLandlord($landlordId: ID!, $blockedReason: String) {
    blockLandlord(landlordId: $landlordId, blockedReason: $blockedReason)
  }
`;

export const POST_VERIFY_LANDLORD = gql`
  mutation PostVerifyLandlord($landlordId: ID!) {
    verifyLandlord(landlordId: $landlordId)
  }
`;

export const DELETE_LANDLORD = gql`
  mutation DeleteLandlord($landlordId: ID!) {
    deleteLandlord(id: $landlordId)
  }
`;
