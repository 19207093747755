import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import {
  POST_BLOCK_LANDLORD,
  POST_NEW_LANDLORD,
  POST_VERIFY_LANDLORD,
  PUT_UPDATE_LANDLORD
} from '../../../../graphql/queries/landlord';
import { LandlordInput } from '../../../../graphql/types/globalTypes';
import { PostNewLandlord, PostNewLandlordVariables } from '../../../../graphql/types/PostNewLandlord';
import { UpdateLandlord, UpdateLandlordVariables } from '../../../../graphql/types/UpdateLandlord';
import { useQueryResultToast } from '../../../../utils/hooks/query-result-toast';
import { LandlordFormDataState } from './landlord-view.state';
import { PostVerifyLandlord, PostVerifyLandlordVariables } from '../../../../graphql/types/PostVerifyLandlord';
import { PostBlockLandlord, PostBlockLandlordVariables } from '../../../../graphql/types/PostBlockLandlord';
import { useEffect } from 'react';
import { useFetchNumberOfNewLandlords } from '../../../../utils/hooks/administration.hooks';

// IMPROVEMENT: Use the default error handler as default props to all queries/mutations

// We already show errors by reacting to the error return object of queries/mutations.
// In addition, we use the error handle link, which automatically logs network/graphql errors.
// Therefore, we don't need a third error handling component, which is why this function is empty
// eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
const DEFAULT_ERROR_HANDLER = (): void => {};

const getUpdateVars = (formState: LandlordFormDataState, id: string): LandlordInput => {
  // Remove read only fields
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    approvalState,
    verifiedAt,
    blockedAt,
    blockedBy,
    verifiedBy,
    createdAt,
    isBlocked,
    blockedReason,
    modifiedAt,
    lastEditedBy,
    password,
    passwordConfirmed,
    uuid,
    privacyPolicyAccepted,
    ...formStateWithoutApproval
  } = formState;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  return Object.assign({ id }, formStateWithoutApproval);
};

export const useCreateAndUpdateMutations = (
  formState: LandlordFormDataState,
  id: string
): {
  createLandlord: () => Promise<FetchResult>;
  updateLandlord: (
    options?: MutationFunctionOptions<UpdateLandlord, UpdateLandlordVariables> | undefined
  ) => Promise<FetchResult<UpdateLandlord>>;
} => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { password, passwordConfirmed, ...formStateCleared } = formState;
  const [createLandlord, { data: creationResultData, error: creationError }] = useMutation<
    PostNewLandlord,
    PostNewLandlordVariables
  >(POST_NEW_LANDLORD, {
    variables: { ...formStateCleared, privacyPolicyAccepted: true, propertyOwnershipConfirmed: true },
    onError: DEFAULT_ERROR_HANDLER
  });
  const [updateLandlord, { data: updateResultData, error: updateLandlordError }] = useMutation<
    UpdateLandlord,
    UpdateLandlordVariables
  >(PUT_UPDATE_LANDLORD, {
    variables: { landlord: getUpdateVars(formState, id) },
    onError: DEFAULT_ERROR_HANDLER
  });

  useQueryResultToast(updateResultData, updateLandlordError, {
    success: {
      titleKey: 'notifications.landlordUpdateSuccess'
    },
    error: {
      titleKey: 'notifications.landlordUpdateError',
      subTitleKey: 'notifications.landlordUpdateErrorSubtitle'
    }
  });

  useQueryResultToast(creationResultData, creationError, {
    success: {
      titleKey: 'notifications.landlordCreationSuccess'
    },
    error: {
      titleKey: 'notifications.landlordCreationError',
      subTitleKey: 'notifications.landlordCreationErrorSubtitle'
    }
  });

  return {
    createLandlord,
    updateLandlord
  };
};

export const useMarkAsSeen = (formState: LandlordFormDataState, isLoaded: boolean): void => {
  const [updateLandlord] = useMutation<UpdateLandlord, UpdateLandlordVariables>(PUT_UPDATE_LANDLORD, {
    variables: {
      landlord: {
        id: formState.id,
        lastName: formState.lastName,
        isNew: false
      }
    },
    onError: DEFAULT_ERROR_HANDLER
  });

  const fetchNumberOfNewLandlords = useFetchNumberOfNewLandlords();

  useEffect(() => {
    if (formState.id !== 'unset' && formState.isNew && isLoaded) {
      updateLandlord().then(fetchNumberOfNewLandlords);
    }
  }, [formState, isLoaded]);
};

export const useVerifyAndBlockMutations = (
  blockedReason: string | null,
  landlordId: string,
  email: string | null
): { blockLandlord: () => void; verifyLandlord: () => Promise<unknown> } => {
  const [verifyLandlord, { data: verifyResultData, error: verifyError }] = useMutation<
    PostVerifyLandlord,
    PostVerifyLandlordVariables
  >(POST_VERIFY_LANDLORD, { variables: { landlordId } });
  const [blockLandlord, { data: blockResultData, error: blockError }] = useMutation<
    PostBlockLandlord,
    PostBlockLandlordVariables
  >(POST_BLOCK_LANDLORD, { variables: { landlordId, blockedReason } });

  useQueryResultToast(
    verifyResultData,
    verifyError,
    {
      success: {
        titleKey: 'notifications.landlordVerificationSuccess',
        subTitleKey: `notifications.landlordVerificationSuccessSubtitle${email ? 'Mail' : ''}`
      },
      error: {
        titleKey: 'notifications.landlordVerificationError',
        subTitleKey: 'notifications.landlordVerificationErrorSubtitle'
      }
    },
    { minWidth: '40rem' }
  );

  useQueryResultToast(
    blockResultData,
    blockError,
    {
      success: {
        titleKey: 'notifications.landlordBlockedSuccess',
        subTitleKey: 'notifications.landlordBlockedSuccessSubtitle'
      },
      error: {
        titleKey: 'notifications.landlordBlockedError',
        subTitleKey: 'notifications.landlordBlockedErrorSubtitle'
      }
    },
    { minWidth: '40rem' }
  );

  return {
    verifyLandlord,
    blockLandlord
  };
};
