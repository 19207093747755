import { FC, useCallback, useEffect, useState } from 'react';
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import footerHTMLDE from './static/footer.de.html.js';
import footerHTMLEN from './static/footer.en.html.js';
import classNames from 'classnames';
import { logger } from '../../../utils/logger';
import { getApiBaseUrl } from '../../../utils/api';
import { ToTopButton } from '../to-top-button/to-top-button';
import { MailSVGIcon, PhoneSVGIcon } from '@react-md/material-icons';

export interface MetaLink {
  href: string;
  translationKey: string;
  isExternal: boolean;
}

export type SocialLinkType = 'rss' | 'fb' | 'ig' | 'li' | 'tw' | 'yt';

export interface SocialLink {
  href: string;
  title: string;
  type: SocialLinkType;
}

export interface ContactInfo {
  phone?: string;
  mail?: string;
}
export interface FooterProps {
  metaLinks: MetaLink[];
  socialLinks: SocialLink[];
  showContactInfo: boolean;
  contactInfo: ContactInfo;
}

export const Footer: FC<FooterProps> = ({ metaLinks, socialLinks, showContactInfo, contactInfo }) => {
  // map language to static fallback html
  const mapLanguage = (lng: string): string => {
    return lng === 'en' ? footerHTMLEN : footerHTMLDE;
  };

  // map language to numerical code used in the footer API
  const mapLanguageToNumericalCode = (lng: string): number => {
    return lng === 'en' ? 1 : 2;
  };

  const { i18n, t } = useTranslation();
  const [currentFooter, setCurrentFooter] = useState<string | null>(null);

  const languageChangeCallback = useCallback(
    (lng) => {
      fetch(`${getApiBaseUrl()}api/footer/?type=2&L=${mapLanguageToNumericalCode(lng)}`)
        .then((response) => response.text())
        .then((footerHTML) => setCurrentFooter(footerHTML))
        .catch((error) => {
          logger.error(`could not fetch footer: ${error}`);
          setCurrentFooter(mapLanguage(lng));
        });
    },
    [i18n, setCurrentFooter]
  );

  useEffect(() => {
    // fetch initial footer if not set
    if (currentFooter === null) {
      languageChangeCallback(i18n.language);
    }

    i18n.on('languageChanged', languageChangeCallback);

    return () => {
      i18n.off('languageChanged', languageChangeCallback);
    };
  }, [i18n, languageChangeCallback, currentFooter]);

  return (
    <footer className={styles.Footer}>
      {showContactInfo && (contactInfo.mail || contactInfo.phone) && (
        <div role="region" aria-label={t('main.feedback')} className={styles.SupportFooter}>
          <div className={styles.SupportFooterTitle}>{t('footer.supportFooterTitle')}</div>
          <div className={styles.SupportFooterSubTitle}>{t('footer.supportFooterSubtitle')}</div>
          <div className={styles.SupportFooterContactInfo}>
            {contactInfo.mail && (
              <a href={`mailto:${contactInfo.mail}`} aria-label={t('views.common.tableHeaders.email')}>
                <MailSVGIcon /> {contactInfo.mail}
              </a>
            )}
            {contactInfo.phone && (
              <a href={`tel:${contactInfo.phone}`} aria-label={t('views.common.tableHeaders.phone')}>
                <PhoneSVGIcon /> {contactInfo.phone}
              </a>
            )}
          </div>
        </div>
      )}
      <ToTopButton />

      <nav className={styles.FooterNavSection} dangerouslySetInnerHTML={{ __html: currentFooter ?? '' }}></nav>

      <nav className={styles.FooterMetaSection}>
        <div className={styles.FooterMetaContent}>
          <ul>
            {metaLinks.map((link, index) => {
              return (
                <li key={index}>
                  <a href={link.href} target={link.isExternal ? '_blank' : undefined}>
                    {t(link.translationKey)}
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className={styles.SocialLinks}>
            {socialLinks.map((link, index) => {
              return (
                <li key={index}>
                  <a
                    href={link.href}
                    title={link.title}
                    className={classNames({
                      [styles.IconRSS]: link.type === 'rss',
                      [styles.IconFB]: link.type === 'fb',
                      [styles.IconIG]: link.type === 'ig',
                      [styles.IconYT]: link.type === 'yt',
                      [styles.IconTW]: link.type === 'tw',
                      [styles.IconLI]: link.type === 'li'
                    })}
                  >
                    {link.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
